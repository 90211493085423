$background-color: #1f222d;
$func-color: #4f5d75;
$accent-color: #ef8354;
$font: "Montserrat", sans-serif;
$screenWidth: 100%;

* {
  padding: 0;
  margin: 0;
  font-family: $font;
}

body {
  background-color: $background-color;
}

main {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.fixed-header {
  display: flex;
  width: $screenWidth;
  height: 60px;
  box-sizing: border-box;
  padding: 0px 40px;
  flex-shrink: 0;
  border-radius: 0px 0px 20px 20px;
  background: $func-color;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: fixed;
  top: -100px;
  right: 0;
  left: 0;
  transition: 0.2s;
  z-index: 1;

  ul {
    display: flex;
    align-items: center;
    gap: 40px;
    list-style: none;

    li {
      a {
        text-decoration: none;
        color: white;
        transition: 0.2s;
      }

      a:hover {
        color: $accent-color;
      }
    }

    li:hover {
      cursor: pointer;
    }
  }

  button {
    display: none;
    background: none;
    border: none;
    justify-content: center;
    align-items: center;
  }
}

.burger-menu {
  height: 100dvh;
  display: flex;
  width: $screenWidth;
  color: white;
  border-radius: 0px 0px 20px 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: fixed;
  justify-content: center;
  align-items: flex-start;
  z-index: 3;
  background-color: $func-color;

  .burger-menu-container {
    width: 94%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .burger-menu-button-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        background: none;
        border: none;
      }
    }

    ul {
      display: flex;
      height: 80%;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      li {
        list-style: none;
      }
    }
  }
}

.non-fixed-burger-menu {
  height: 400px;
  display: flex;
  width: $screenWidth;
  color: white;
  border-radius: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  background-color: $func-color;
  position: absolute;

  .non-fixed-burger-menu-container {
    width: 94%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    ul {
      display: flex;
      height: 85%;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      li {
        list-style: none;
      }
    }
  }
}

.fixed {
  top: 0px;
}

.home-page {
  height: 100lvh;
  width: $screenWidth;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  h1 {
    color: #fff;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    span {
      color: #ef8354;
    }
  }

  a {
    display: flex;
    width: 230px;
    height: 80px;
    padding: 20.5px 41px 20.5px 42px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    background: $func-color;
    font-size: 32px;
    color: #fff;
    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  a:hover {
    background-color: $accent-color;
  }
}

.about-page {
  display: flex;
  min-height: 100lvh;
  width: $screenWidth;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  .avatar-desc {
    display: flex;
    width: 80%;
    height: 432px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    span {
      max-width: 350px;
      color: #fff;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      flex-direction: column;
      gap: 14px;

      p {
        font-family: Montserrat;
        font-size: 24px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .non-fixed-header {
    display: flex;
    width: $screenWidth;
    height: 60px;
    box-sizing: border-box;
    padding: 0px 40px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #4f5d75;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 2;

    ul {
      display: flex;
      align-items: center;
      gap: 40px;
      list-style: none;

      li {
        a {
          text-decoration: none;
          color: white;
          transition: 0.2s;
        }

        a:hover {
          color: $accent-color;
        }
      }

      li:hover {
        cursor: pointer;
      }
    }

    button {
      display: none;
      background: none;
      border: none;
      justify-content: center;
      align-items: center;
    }
  }

  .skills {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 900px;
    flex-wrap: wrap;
    div {
      display: flex;
      flex-direction: column;
      color: #fff;
      align-items: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .skill-icon {
      &:hover {
        span {
          opacity: 1;
          transform: translateY(0);
          transition: 0.2s;
        }
        cursor: pointer;
      }
      span {
        opacity: 0;
        transform: translateY(-20px);
        transition: 0.2s;
      }
    }
  }
}

.portfolio-page {
  height: 88lvh;
  width: $screenWidth;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .portfolio-page-container {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    height: 100%;
    width: 80%;

    a {
      text-decoration: none;
      display: block;
      width: 40%;
      flex-grow: 1;
      min-width: 320px;
      div {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 20px;
        display: flex;
        align-items: flex-end;

        div {
          display: flex;
          width: 100%;
          height: 60px;
          padding: 0px 20px;
          box-sizing: border-box;
          align-items: center;
          flex-shrink: 0;
          background: rgba(46, 46, 46, 0.75);
          color: #fff;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .genagrad-proj {
    background-image: url(../assets/img/genagrad.png);
  }

  .old-website-proj {
    background-image: url(../assets/img/old-website.png);
  }

  .forstore-proj {
    background-image: url(../assets/img/forstore.png);
  }

  .SusMarket-proj {
    background-image: url(../assets/img/SusMarket.png);
  }
}

.contacts-page {
  display: flex;
  width: $screenWidth;
  height: 84lvh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .request {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    box-sizing: border-box;
    justify-content: center;
    gap: 20px;

    p {
      width: 40%;
      min-width: 240px;
    }

    form {
      display: flex;
      height: 415px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      min-width: 300px;

      input {
        display: flex;
        height: 50px;
        padding: 0px 20px;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 10px;
        background: #15171e;
        border: none;
        color: white;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      input::-webkit-input-placeholder,
      textarea::-webkit-input-placeholder {
        font-family: $font;
      }
      input:-ms-input-placeholder,
      textarea:-ms-input-placeholder {
        font-family: $font;
      }
      input:-moz-placeholder,
      textarea:-moz-placeholder {
        font-family: $font;
      }
      input::-moz-placeholder,
      textarea::-moz-placeholder {
        font-family: $font;
      }

      textarea {
        display: flex;
        height: 200px;
        padding: 20px;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 10px;
        background: #15171e;
        border: none;
        color: white;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        resize: none;
      }

      button {
        display: flex;
        height: 50px;
        padding: 0px 20px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 10px;
        background: #15171e;
        border: none;
        color: #757575;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        transition: all 0.2s;
      }

      button:hover {
        color: white;
      }
    }
  }

  .social-links {
    display: flex;
    width: 60%;
    justify-content: space-between;

    a {
      svg {
        path {
          transition: 0.2s;
        }
      }

      svg:hover path {
        fill: $accent-color;
      }
    }
  }
}

footer {
  display: flex;
  height: 14%;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #15171e;
  color: #303444;
}

@media (max-width: 1200px) {
  .portfolio-page-container {
    width: 90%;
  }
}

@media (max-width: 1099px) {
  .request {
    width: 80% !important;
  }

  .social-links {
    width: 80% !important;
  }
}

@media (max-width: 929px) {
  .avatar-desc {
    img {
      width: 200px;
    }

    span {
      font-size: 28px !important;

      p {
        font-size: 20px !important;
      }
    }
  }
}

@media (max-width: 900px) {
  .home-page {
    div {
      width: 90%;
    }
  }
}

@media (max-width: 699px) {
  .contacts-page {
    height: 104lvh;
    .request {
      p {
        width: 100% !important;
      }
      form {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 600px) {
  .fixed-header {
    justify-content: flex-end;
    padding: 0px 20px;

    ul {
      display: none;
    }

    button {
      display: flex;
    }
  }
  .non-fixed-header {
    justify-content: flex-end;
    padding: 0px 20px !important;

    ul {
      display: none !important;
    }

    button {
      display: flex !important;
    }
  }
  .social-links {
    a {
      svg {
        width: 70px;
      }
    }
  }
}

@media (max-width: 538px) {
  form {
    height: auto !important;
    gap: 5px;
    textarea {
      height: 150px !important;
    }
  }
}

@media (max-width: 465px) {
  .skill-icon {
    width: 70px;
    img {
      width: 70px;
    }
    span {
      font-size: 12px !important;
    }
  }
}

@media (max-width: 425px) {
  .home-page {
    div {
      h1 {
        font-size: 26px !important;
      }
      div {
        a {
          width: 180px;
          font-size: 22px;
          padding: 0;
          height: 70px;
        }
      }
    }
  }
  .about-page {
    justify-content: flex-start !important;
    gap: 60px !important;
  }
  .portfolio-page {
    .portfolio-page-container {
      a {
        min-width: 1px;
        width: 100%;
      }
    }
  }
  .social-links {
    a {
      svg {
        width: 50px;
      }
    }
  }
}
